import { xinProxy } from 'xinjs'
import * as fb from './firebase'

export const { app } = xinProxy(
  {
    app: {
      title: 'inconsequence',
      subtitle: 'musings on subjects of passing interest',
      showSignIn: window.location.pathname.includes('/blog/admin'),
      fb,
      user: {} as any,
    },
  },
  true
)

fb.authStateChangeListeners.add(async () => {
  app.user = await fb.service.user.get()
  console.log(app.user)
})
