import { elements, vars, bindings } from 'xinjs'
import { icons, popMenu } from 'xinjs-ui'
import './style'
import { blog } from './blog'
import { xinPage } from './page'
import { theme } from './style'
import { app } from './app'

const { a, h2, h3, img, button, div, header, main, footer } = elements

declare global {
  interface Window {
    app: typeof app
    blog: typeof blog
  }
}

window.app = app
window.blog = blog

bindings.loading = {
  toDOM(element, truthy) {
    const isLoading =
      !(typeof truthy === 'object' && truthy.valueOf()) && !truthy
    element.classList.toggle('loading', isLoading)
  },
}

// comment
document.body.append(
  main(
    {
      bindLoading: blog.currentPost.content,
    },
    header(
      { class: 'row padded', style: { paddingRight: 0, alignItems: 'center' } },
      a(
        {
          href: '/',
          style: {
            flex: `0 0 ${vars.touchSize}`,
            // dunno why this is needed but without there's a Chrome rendering issue
            maxHeight: vars.touchSize,
          },
        },
        img({
          alt: 'xinjs logo',
          src: '/logo.svg',
          class: 'logo',
        })
      ),
      div(
        { class: 'stack elastic crop' },
        h2({ class: 'trim', bindText: app.title }),
        h3({
          class: 'trim nowrap ellipsis',
          bindText: app.subtitle,
        })
      ),
      div({ class: 'elastic' }),
      button(
        {
          title: 'Menu',
          class: 'iconic',
          onClick(event: Event) {
            const target = event.target as HTMLButtonElement
            popMenu({
              target,
              menuItems: [
                {
                  icon: 'info',
                  caption: `About…`,
                  action() {
                    blog.loadPost('post/path=it-begins')
                  },
                },
                null,
                {
                  caption: 'Theme',
                  menuItems: [
                    {
                      icon: 'sun',
                      caption: 'Light',
                      checked: () => theme.mode.valueOf() === 'light',
                      action() {
                        theme.mode = 'light'
                      },
                    },
                    {
                      icon: 'moon',
                      caption: 'Dark',
                      checked: () => theme.mode.valueOf() === 'dark',
                      action() {
                        theme.mode = 'dark'
                      },
                    },
                    null,
                    {
                      icon: 'settings',
                      caption: 'System',
                      checked: () => theme.mode.valueOf() === 'system',
                      action() {
                        theme.mode = 'system'
                      },
                    },
                  ],
                },
                ...(app.showSignIn.valueOf() || app.fb.getFirebaseUser()
                  ? [
                      null,
                      app.fb.getFirebaseUser()
                        ? {
                            icon: 'logOut',
                            caption: 'Sign Out',
                            action() {
                              app.fb.userSignout()
                            },
                          }
                        : {
                            icon: 'logIn',
                            caption: 'Sign In',
                            action() {
                              app.fb.signinWithGoogle()
                            },
                          },
                    ]
                  : []),
              ],
            })
          },
        },
        icons.chevronDown()
      )
    ),
    xinPage({ page: { source: '<xin-blog></xin-blog>' } }),
    footer(
      {
        class: 'responsive-row padded center-justify',
        style: { position: 'relative' },
      },
      `Copyright ©2003-${new Date().getFullYear()} Tonio Loewald`,
      a(
        {
          title: 'Sandra Bullock is awesome!',
          style: {
            opacity: 0.05,
            textDecoration: 'none',
            position: 'absolute',
            top: 0,
            right: 0,
            padding: vars.pad,
          },
          href: '/blog/admin/',
        },
        'π'
      )
    )
  )
)
