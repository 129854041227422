import {
  xinProxy,
  Component,
  elements,
  vars,
  varDefault,
  bindings,
  getListItem,
  xinValue,
} from 'xinjs'
import {
  markdownViewer,
  sideNav,
  SideNav,
  postNotification,
  tabSelector,
  codeEditor,
  icons,
  popMenu,
  LiveExample,
  MarkdownViewer,
} from 'xinjs-ui'
import * as xinjs from 'xinjs'
import * as xinjsui from 'xinjs-ui'
import { service, ServiceRequestType } from './firebase'
import { getPrefetchedDoc } from './prefetched'
import { app } from './app'
import { randomID } from './random-id'
import { assetManager } from './asset-manager'

export interface BlogRef {
  _path?: string
  title: string
  path: ''
  date?: string
  keywords?: string[]
  summary: string
}

export interface BlogPost extends BlogRef {
  content: string
  author: string
}

const emptyPost: BlogPost = {
  title: '',
  path: '',
  content: '',
  date: '',
  keywords: [],
  summary: '',
  author: '',
}

export interface Asset {
  name: string
  id: string
}

const toggleAssetManagerItem = () => ({
  icon: 'image',
  caption: assets.closest('body') ? 'Hide Asset Manager' : 'Asset Manager',
  action() {
    if (assets.closest('body')) {
      assets.remove()
    } else {
      document.body.append(assets)
    }
  },
})

const assets = assetManager()

export const { blog } = xinProxy(
  {
    blog: {
      title: 'inconsequence',
      index: [] as BlogRef[],
      filtered: [] as BlogRef[],
      visiblePosts: 6,
      currentPost: { ...emptyPost },
      editorPost: { ...emptyPost },
      otherPosts: [] as BlogPost[],
      route: '/blog',
      linkFromRef(ref: BlogRef): string {
        const date = ref.date != '' ? new Date(ref.date as string) : new Date()
        return `${blog.route}/${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()}/${ref.path}`
      },
      async getIndex(c = 30, skipPrefetched = false): Promise<BlogPost[]> {
        const recentPosts = await getPrefetchedDoc('recentPosts', false)
        if (!skipPrefetched && recentPosts && recentPosts.length >= c) {
          return recentPosts
        }
        const roles = app.user?.roles || []
        const o =
          roles.includes('author') || roles.includes('editor')
            ? ''
            : 'date(desc)'
        return await service.docs.get({
          p: 'post',
          f: 'title,date,summary,keywords,path',
          o,
          c,
        })
      },
      async getLatest(count = 1): Promise<BlogPost[]> {
        const latestPosts = (await getPrefetchedDoc(
          'latestPosts',
          false
        )) as string[]
        if (latestPosts && latestPosts.length >= count) {
          return Promise.all(
            latestPosts
              .slice(0, count)
              .map((path: string) =>
                getPrefetchedDoc(`post/path=${path}`, false)
              )
          )
        } else {
          console.log(`fetching ${count} latest posts`)
          return await service.docs.get({
            p: 'post',
            c: count,
            o: 'date(desc)',
          })
        }
      },
      async getPost(id?: string): Promise<BlogPost | undefined> {
        let p: string
        if (!id) {
          const [ref] = await blog.getLatest()
          p = ref._path as string
        } else {
          p = `post/${id}`
        }
        return getPrefetchedDoc(p)
      },
      async onLinkClick(event: Event) {
        event.stopPropagation()
        event.preventDefault()
        const post = getListItem(event.target as HTMLElement)
        if (post.content) {
          blog.currentPost = post
          blog.fixMetadata(post)
        } else {
          const loaded = await blog.loadPost(
            `post/path=${post.path}`,
            post.title
          )
          if (!loaded) return
        }
        const path = blog.linkFromRef(blog.currentPost)
        window.history.pushState({ path }, '', path)
        const blogElement = document.querySelector('xin-blog') as XinBlog
        if (blogElement) {
          blogElement.showPost()
        }
      },
      async postPathFromLocation(): Promise<string | undefined> {
        const path = window.location.pathname
        let [, , postPath] = path.match(/\/blog\/(\d+\/)*([\w-]+)$/) || []
        if (postPath) {
          return `post/path=${postPath}`
        }
        const urlParams = new URLSearchParams(window.location.search)
        const postId = urlParams.get('p')
        if (postId) {
          return `post/${postId}`
        }
        const latestPosts = await getPrefetchedDoc('latestPosts', false)
        if (latestPosts && latestPosts.length) {
          return `post/path=${latestPosts[0]}`
        }
      },
      async loadPost(
        p?: string,
        title = 'post'
      ): Promise<BlogPost | undefined> {
        if (!p) {
          p = await blog.postPathFromLocation()
          if (!p) {
            return
          }
        }
        const closeNotification = postNotification({
          message: `loading ${title}`,
          type: 'progress',
        })
        const post = await getPrefetchedDoc(p)
        closeNotification()
        if (post) {
          blog.fixMetadata(post)
        } else {
          postNotification({
            message: 'load failed',
            type: 'error',
            duration: 2,
          })
        }
        if (post) {
          blog.currentPost = post
        }
        return post as BlogPost
      },
      fixMetadata(post: BlogPost) {
        document.title = blog.title + ' | ' + post.title
        ;(
          document.head.querySelector(
            'meta[name="description"]'
          ) as HTMLMetaElement
        ).content = post.summary
        ;(
          document.head.querySelector(
            'meta[property="og:title"]'
          ) as HTMLMetaElement
        ).content = post.title
        ;(
          document.head.querySelector(
            'meta[property="og:url"]'
          ) as HTMLMetaElement
        ).content = blog.linkFromRef(post)
        ;(
          document.head.querySelector(
            'meta[property="og:description"]'
          ) as HTMLMetaElement
        ).content = post.summary
        ;(
          document.head.querySelector(
            'meta[property="og:image"]'
          ) as HTMLMetaElement
        ).content
      },
      filterIndex(needle: string) {
        needle = needle.toLocaleLowerCase()
        blog.filtered = blog.index.filter(
          (ref) =>
            ref.title.toLocaleLowerCase().includes(needle) ||
            (ref.keywords && ref.keywords.find((word) => word.includes(needle)))
        )
      },
      editPost(post?: BlogPost) {
        blog.editorPost = post
          ? xinValue(post)
          : {
              ...emptyPost,
              author: app.user.name.valueOf(),
              title: 'untitled blog post',
            }
        document.body.append(xinPostEditor())
      },
    },
  },
  true
)

async function initBlog() {
  console.time('post loaded')

  const post = await blog.loadPost()
  if (post) {
    console.timeEnd('post loaded')
  }

  console.time('recent posts loaded')
  const posts = await blog.getLatest(blog.visiblePosts)

  if (!blog.currentPost || blog.currentPost.content == '') {
    blog.currentPost = posts[0] || emptyPost
    console.timeEnd('post loaded')
  }

  blog.otherPosts = [...posts]
  console.timeEnd('recent posts loaded')

  console.time('blog index loaded')
  blog.index = blog.filtered = await blog.getIndex()
  console.timeEnd('blog index loaded')
}

initBlog().then(() => {
  console.log('blog loaded')
})

const {
  div,
  h1,
  h2,
  h3,
  p,
  a,
  span,
  img,
  nav,
  label,
  input,
  button,
  textarea,
  template,
  xinSlot,
} = elements

bindings.date = {
  toDOM(element, dateString) {
    element.textContent = dateString
      ? new Date(dateString).toLocaleDateString()
      : 'Not Published'
  },
}

bindings.image = {
  toDOM(element, content) {
    if (!content || !content.match) {
      content = 'no content found'
    }
    const [, src] =
      content.match(/!\[[^\]]+\]\((.*?)\)/) ||
      content.match(/<img[^>]+src="(.*?)"/) ||
      []
    const [, alt] = content.match(/!\[([^\]]+)\]\(.*?\)/) ||
      content.match(/<img[^>]+alt="(.*?)"/) || ['illustration']

    element.textContent = ''
    if (src) {
      element.append(img({ alt: alt, src }))
    }
  },
}

bindings.blogLink = {
  toDOM(element, blogRef) {
    if (blogRef) {
      const link = blog.linkFromRef(blogRef)
      element.setAttribute('href', link)
    }
  },
}

bindings.visibleIfAuthor = {
  toDOM(element, user) {
    if (user?.roles?.includes('author')) {
      element.style.display = ''
    } else {
      element.style.display = 'none'
    }
  },
}

bindings.hideCurrentPost = {
  toDOM(element, currentPostPath) {
    const post = getListItem(element)
    if (post.path === currentPostPath) {
      element.setAttribute('hidden', '')
    } else {
      element.removeAttribute('hidden')
    }
  },
}
export class XinBlogPost extends Component {
  boundPost = blog.currentPost

  content = () =>
    div(
      div(
        { style: { display: 'flex' } },
        xinSlot({ name: 'before-title' }),
        h1({
          bindText: this.boundPost.title,
          style: { marginTop: 0, flex: '1 1 auto' },
        }),
        xinSlot()
      ),
      markdownViewer({
        bindValue: this.boundPost.content,
        didRender(this: MarkdownViewer) {
          LiveExample.insertExamples(this, { xinjs, xinjsui })
        },
      }),
      p(
        { style: { textAlign: 'right', marginTop: vars.xinBlogPad } },
        '— ',
        span({ bindText: this.boundPost.author }),
        ', ',
        span({ bindDate: this.boundPost.date })
      )
    )
}

export const xinBlogPost = XinBlogPost.elementCreator({ tag: 'xin-blog-post' })

export class XinBlogPostList extends Component {
  list = blog.otherPosts

  content = () =>
    div(
      {
        bindList: {
          value: this.list,
          idPath: '_path',
        },
      },
      template(
        div(
          { class: 'post-summary', bindHideCurrentPost: blog.currentPost.path },
          div(
            { class: 'row', style: { alignItems: 'baseline' } },
            h3({ bindText: '^.title' }),
            span({ class: 'elastic' }),
            p({ bindDate: '^.date' })
          ),
          div(
            { class: 'row' },
            div({ bindImage: '^.content' }),
            div(
              { class: 'stack' },
              p({ bindText: '^.summary' }),
              p(
                a(
                  { bindBlogLink: '^', onClick: blog.onLinkClick },
                  'Read the post…'
                )
              )
            )
          )
        )
      )
    )
}

export const xinBlogPostList = XinBlogPostList.elementCreator({
  tag: 'xin-blog-post-list',
})

export class XinBlog extends Component {
  search = () => {
    const nav = this.parts.sidenav as SideNav
    if (nav.compact) {
      nav.contentVisible = false
      this.parts.search.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }

  showPost() {
    const nav = this.parts.sidenav as SideNav
    nav.contentVisible = true
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  showBlogMenu = () => {
    popMenu({
      target: this.parts.menuTrigger,
      menuItems: [
        {
          icon: 'filePlus',
          caption: 'New Post',
          action() {
            blog.editPost()
          },
        },
        {
          icon: 'editDoc',
          caption: 'Edit Post',
          enabled() {
            return !!blog.currentPost.content
          },
          action() {
            blog.editPost(blog.currentPost)
          },
        },
        null,
        toggleAssetManagerItem(),
      ],
    })
  }

  connectedCallback() {
    super.connectedCallback()

    window.addEventListener('popstate', () => {
      blog.loadPost()
    })
  }

  content = () =>
    sideNav(
      {
        part: 'sidenav',
        navSize: 250,
        minSize: 700,
        style: {
          flex: '1 1 auto',
          overflow: 'hidden',
        },
      },
      div(
        {
          style: {
            display: 'flex',
            flexDirection: 'column',
            padding: vars.xinBlogPad,
            gap: vars.xinBlogPad,
          },
        },
        xinBlogPost(
          button(
            {
              part: 'show-sidebar',
              slot: 'before-title',
              class: 'iconic',
              style: {
                marginLeft: vars.xinBlogPad_100,
              },
              title: 'show navigation',
              onClick: this.search,
            },
            icons.chevronLeft()
          ),
          button(
            {
              part: 'menuTrigger',
              title: 'Blog Menu',
              class: 'iconic',
              onClick: this.showBlogMenu,
              bindVisibleIfAuthor: app.user,
              style: {
                display: 'none',
                marginRight: vars.xinBlogPad_100,
              },
            },
            icons.blog()
          )
        ),
        h2('Recent Posts'),
        xinBlogPostList()
      ),
      xinBlogSearch({ part: 'search', slot: 'nav' })
    )
}

export const xinBlog = XinBlog.elementCreator({
  tag: 'xin-blog',
  styleSpec: {
    'xin-blog, xin-blog-post, xin-blog-search, xin-post-editor': {
      _xinBlogPad: varDefault.pad('10px'),
      _xinBlogBodyBg: varDefault.bodyBg('white'),
      _spacing: varDefault.pad('10px'),
      _xinTabsSelectedColor: varDefault.brandColor('blue'),
    },

    ':host xin-sidenav:not([compact]) [part="show-sidebar"]': {
      display: 'none',
    },
  },
})

export class XinBlogSearch extends Component {
  loadIndex = async () => {
    const closeNotification = postNotification({
      message: `downloading full index`,
      type: 'progress',
    })
    blog.index = await blog.getIndex(2000, true)
    closeNotification()
    ;(this.parts.searchField as HTMLInputElement).placeholder =
      'search all posts'
    ;(this.parts.downloadIndex as HTMLButtonElement).remove()
  }

  content = () =>
    nav(
      {
        class: 'responsive-stack padded',
        style: {
          _baseWidth: vars.listWidth,
        },
      },
      div(
        {
          class: 'responsive-stack',
          style: {
            flex: `0 0 calc(100vh - 82px - ${vars.xinBlogPad200})`,
            gap: vars.xinBlogPad,
          },
        },
        div(
          {
            style: {
              display: 'flex',
            },
          },
          input({
            part: 'searchField',
            placeholder: 'search recent posts',
            type: 'search',
            style: {
              margin: '2px',
              minWidth: '10px',
              flex: '1 1 auto',
            },
            onInput(event: Event) {
              const target = event.target as HTMLInputElement
              if (target.value) {
                blog.filterIndex(target.value)
              } else {
                blog.filtered = blog.index
              }
            },
          }),
          button(
            {
              title: 'Download Full Index',
              part: 'downloadIndex',
              class: 'iconic',
              style: {
                flex: '0 0 36px',
                height: '36px',
                lineHeight: '36px',
              },
              onClick: this.loadIndex,
            },
            icons.downloadCloud()
          )
        ),
        div(
          {
            class: 'stack elastic',
            style: {
              overflowY: 'auto',
            },
            bindList: {
              value: blog.filtered,
              idPath: '_path',
            },
          },
          template(
            a({
              class: 'trim nowrap ellipsis rigid',
              bindText: '^.title',
              bindBlogLink: '^',
              onClick: blog.onLinkClick,
            })
          )
        )
      )
    )
}

export const xinBlogSearch = XinBlogSearch.elementCreator({
  tag: 'xin-blog-search',
})

export class XinPostEditor extends Component {
  updateContent = () => {
    blog.editorPost.content = (this.parts.content as HTMLInputElement).value
  }

  closeEditor = () => {
    this.remove()
  }

  savePost = async () => {
    let method: ServiceRequestType = 'put'
    if (!blog.editorPost._path) {
      blog.editorPost._path = `post/${randomID()}`
      method = 'post'
    }
    this.updateContent()
    const closeNotification = postNotification({
      message: `saving ${blog.editorPost.title}`,
      type: 'progress',
    })
    const data = xinValue(blog.editorPost)
    const result = await service.doc[method]({ p: data._path, data })
    closeNotification()
    if (result instanceof Error) {
      postNotification({
        message: result.toString(),
        type: 'error',
      })
    }
  }

  unpublish = () => {
    blog.editorPost.date = ''
  }

  publishNow = () => {
    blog.editorPost.date = new Date().toISOString()
  }

  showEditorMenu = () => {
    popMenu({
      target: this.parts.menuTrigger,
      menuItems: [
        toggleAssetManagerItem(),
        {
          caption: 'Save',
          icon: 'uploadCloud',
          action: this.savePost,
        },
        null,
        {
          caption: 'Close',
          icon: 'x',
          action: this.closeEditor,
        },
      ],
    })
  }

  content = () =>
    div(
      {
        style: {
          position: 'fixed',
          background: vars.xinBlogBodyBg,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          gap: vars.xinBlogPad,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      },
      tabSelector(
        {
          style: {
            flex: '1 1 auto',
          },
          onChange: this.updateContent,
        },
        button(
          {
            slot: 'after-tabs',
            part: 'menuTrigger',
            title: 'Editor Menu',
            class: 'iconic',
            onClick: this.showEditorMenu,
            style: {
              height: '40px',
              lineHeight: '40px',
            },
          },
          icons.chevronDown()
        ),
        div(
          {
            name: 'Content',
            style: {
              height: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              gap: vars.xinBlogPad50,
            },
          },
          input({
            bindValue: blog.editorPost.title,
            style: {
              marginTop: vars.xinBlogPad50,
            },
          }),
          codeEditor({
            part: 'content',
            style: {
              flex: '1 1 auto',
              resize: 'none',
            },
            value: blog.editorPost.content.replace(/\n{3,}/g, '\n\n'),
          })
        ),
        div(
          { name: 'Preview', style: { padding: vars.xinBlogPad } },
          xinBlogPost({
            boundPost: blog.editorPost,
          })
        ),
        div(
          { name: 'Metadata', style: { padding: vars.xinBlogPad } },
          label(span('Path'), input({ bindValue: blog.editorPost.path })),
          label(
            div(
              {
                class: 'row',
                style: { alignItems: 'center', gap: vars.pad50 },
              },
              span('Publication Date'),
              span({ class: 'elastic' }),
              button('Unpublish', { onClick: this.unpublish }),
              button('Publish Now', { onClick: this.publishNow })
            ),
            input({ part: 'publicationDate', bindValue: blog.editorPost.date })
          ),
          label(
            span('Summary'),
            textarea({ bindValue: blog.editorPost.summary })
          )
        )
      )
    )
}

export const xinPostEditor = XinPostEditor.elementCreator({
  tag: 'xin-post-editor',
  styleSpec: {
    ':host label': {
      display: 'flex',
      flexDirection: 'column',
      gap: vars.xinBlogPad50,
      margin: `${vars.xinBlogPad50} 0`,
      alignItems: 'stretch',
    },
    ':host textarea': {
      width: '100%',
      resize: 'vertical',
      minHeight: '200px',
      fontFamily: vars.codeFont,
      fontSize: '16px',
    },
    ':host xin-code': {
      fontFamily: vars.codeFont,
    },
    ':host input': {
      margin: '2px',
    },
  },
})
